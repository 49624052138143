import { useEffect, useRef } from 'react';
import { captureVideoFrame } from 'utils/captureVideoFrame';
import { hexToHSL, hslToHex } from 'utils/colorFormatCoverter';
import { getAverageRGB } from 'utils/getAverageRGB';

export function useDominantColor(
	videoRef: any,
	callback: (color: string | null) => any,
	tick: number
) {
	const intervalIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

	const start = useRef<() => void>(() => {
		// Stop previous runs
		stop.current();
		// Start get stats loop
		intervalIdRef.current = setInterval(async () => {
			// Capture the current frame
			const frame = captureVideoFrame(videoRef?.current, 'png') as any;

			// Limit color range
			if (!frame.imageData) return;
			const hslColor = hexToHSL(getAverageRGB(frame.imageData));
			const color = hslToHex(hslColor.h, hslColor.s, Math.max(hslColor.l, 20));
			callback(color);
		}, tick);
	});

	const stop = useRef<() => void>(() => {
		// Remove previous loop
		clearInterval(intervalIdRef.current!);
		callback(null);
	});

	useEffect(() => {
		return () => {
			stop.current();
		};
	}, []);

	return { start: start.current, stop: stop.current };
}
