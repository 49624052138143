import Snackbar from 'components/snackbar';
import useDelayedShowOrHide from 'hooks/useDelayedShowOrHide';
import genericAlertIcon from 'images/alert.svg';
import redAlertIcon from 'images/red-alert.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';
import { useMemo, useState } from 'react';
import './index.scss';
type Props = {
	isDrivingImpaired: boolean;
	penalty: number;
	dockControllerStatus?: any;
};

const showOrHideConfig = {
	showDelayMs: 0, // show the indicator immediately when driving is impaired
	hideDelayMs: 1000, // hide the indicator after a delay of X ms, when driving is no longer impaired
};

export default function ImpairedDrivingIndicator({
	isDrivingImpaired,
	penalty,
	dockControllerStatus,
}: Props) {
	const [isMinimized, setIsMinimized] = useState(false);
	const isVisible = useDelayedShowOrHide(isDrivingImpaired, showOrHideConfig);

	const icon = useMemo(() => (penalty === 1 ? redAlertIcon : genericAlertIcon), [penalty]);

	return isMinimized ? (
		<div
			className={isVisible ? 'drivingImpairmentCircle' : 'displayNone'}
			onClick={() => setIsMinimized(false)}
		>
			<div className="warningIconWrapper">
				<img src={icon} alt="" />
			</div>
		</div>
	) : isVisible ? (
		<Snackbar
			icon={icon}
			iconWrapper={whiteCloseChevronIcon}
			title="Whoops!"
			actionRequired="There seems to be a problem with your Network Connection.Driving speed is now limited"
		/>
	) : null;
}
