import { SessionType } from 'GoBeWebRTC/types';
import { downloadBlob } from 'GoBeWebRTC/utils';
import { useEffect, useMemo, useRef, useState } from 'react';

enum StatMode {
	'Default',
	'Intensive',
}

type SessionSwitchStats = PeerConnectionSwitchStat[];

type PeerConnectionSwitchStat = {
	peerConnectionId: number;
	candidatePairs: {
		local: RTCIceCandidate;
		remote: RTCIceCandidate;
	};
	transceiverSwitchStats: TransceiverSwitchStat[];
};

type TransceiverSwitchStat = {
	mid: string;
	kind: 'video' | 'audio';
	switchStats: SwitchStat[];
};

type ParsedTransceiverSwitchStat = {
	mid: string;
	lastRtcStatsReport: any;
	frameStack: any;
	currentSwitchStats: SwitchStatTimestamp;
	statsId: number;
};

type SwitchStat = {
	id: number;
	timestamps: SwitchStatTimestamp;
	intervals: {
		lostFramesToIceRestart?: number;
		iceRestartToLostFrames?: number;
		iceRestartAtToGainedFramesAt: number;
		lostFramesAtToGainedFramesAt: number;
		gainedFramesAtToStableFramesAt?: number;
		lostFramesAtToStableFramesAt?: number;
	};
	status: 'succeeded' | 'failed';
};

type SwitchStatTimestamp = {
	lostFramesAt: number | null;
	iceRestartAt: number | null;
	gainedFramesAt: number | null;
	stableFramesAt: number | null;
};

export function useStats(
	peerConnection: RTCPeerConnection,
	sessionInfo: { robot: string; environment: string; sessionId: string; sessionType: SessionType },
	onSwitchStatCallback: (stat: any) => any
) {
	const [mode, setMode] = useState<StatMode>(
		sessionInfo.sessionType === 'testing' ? StatMode.Intensive : StatMode.Default
	);
	const modeRef = useRef<StatMode>(mode);
	const defaultSampleRate = modeRef.current === StatMode.Intensive ? 100 : 1000;
	const stableFramesPerSecond = 20;
	const intervalIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
	const sessionSwitchStatsRef = useRef<SessionSwitchStats>([]);
	const peerConnectionIdRef = useRef<number>(0);
	const transceiverSwitchStatsRef = useRef<ParsedTransceiverSwitchStat[]>([]);
	const switchStatInit: SwitchStatTimestamp = {
		lostFramesAt: null,
		iceRestartAt: null,
		gainedFramesAt: null,
		stableFramesAt: null,
	};
	const statsRef = useRef<any>();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const stats = useMemo(() => statsRef.current, [statsRef.current]);
	const stableFramesCallbacksRef = useRef<(() => any)[]>([]);

	// Parse switch stat
	const parseSwitchStat: (
		transceiverSwitchStats: ParsedTransceiverSwitchStat,
		isVideo: boolean
	) => SwitchStat = (transceiverSwitchStats, isVideo) => {
		const { lostFramesAt, iceRestartAt, gainedFramesAt, stableFramesAt } =
			transceiverSwitchStats?.currentSwitchStats;
		const iceRestartFirst = lostFramesAt! > iceRestartAt!;
		return {
			id: transceiverSwitchStats?.statsId!,
			timestamps: transceiverSwitchStats?.currentSwitchStats,
			intervals: {
				[iceRestartFirst ? 'iceRestartToLostFrames' : 'lostFramesToIceRestart']:
					(iceRestartFirst ? 1 : -1) * (lostFramesAt! - iceRestartAt!),
				iceRestartAtToGainedFramesAt: gainedFramesAt! - iceRestartAt!,
				lostFramesAtToGainedFramesAt: gainedFramesAt! - lostFramesAt!,
				...(isVideo
					? {
							gainedFramesAtToStableFramesAt: stableFramesAt! - gainedFramesAt!,
							lostFramesAtToStableFramesAt: stableFramesAt! - lostFramesAt!,
					  }
					: {}),
			},
			status: stableFramesAt ? 'succeeded' : 'failed',
		};
	};

	// Add switch stat to overall session switch stat
	const addSessionStat: (mid: string, kind: 'audio' | 'video', stat: SwitchStat) => void = (
		mid,
		kind,
		stat
	) => {
		if (modeRef.current === StatMode.Intensive) {
			const peerConnectionStats = sessionSwitchStatsRef.current.find(
				(value) => value.peerConnectionId === peerConnectionIdRef.current
			);
			if (peerConnectionStats) {
				const transceiverSwitchStat = peerConnectionStats.transceiverSwitchStats.find(
					(t) => t.mid === mid
				);
				if (transceiverSwitchStat) transceiverSwitchStat.switchStats.push(stat);
				else
					peerConnectionStats.transceiverSwitchStats.push({
						mid,
						kind,
						switchStats: [stat],
					});
			} else {
				sessionSwitchStatsRef.current.push({
					peerConnectionId: peerConnectionIdRef.current,
					candidatePairs: {
						local: statsRef.current?.succeededCandidatePair?.local!,
						remote: statsRef.current?.succeededCandidatePair?.remote!,
					},
					transceiverSwitchStats: [{ mid, kind, switchStats: [stat] }],
				});
			}
		}
	};

	// Callback when getting rtc stats report from the browser peer connection
	const onRTCStatsReport = (rtcStatsReport: any) => {
		rtcStatsReport.forEach((report: any) => {
			switch (report.type) {
				case 'candidate-pair':
					if (report.nominated && report.state === 'succeeded') {
						const succeededCandidatePair: any = {
							local: rtcStatsReport.get(report.localCandidateId),
							remote: rtcStatsReport.get(report.remoteCandidateId),
						};
						let scopedFields = ['candidateType', 'relayProtocol', 'protocol'];
						if (mode === StatMode.Intensive)
							scopedFields = scopedFields.concat(['address', 'port']);
						if (
							!(
								statsRef.current?.succeededCandidatePair &&
								scopedFields.every((key) =>
									['local', 'remote'].every(
										(type) =>
											succeededCandidatePair[type][key] ===
											statsRef.current?.succeededCandidatePair?.[type][key]
									)
								)
							)
						)
							statsRef.current = {
								succeededCandidatePair,
							};
					}
					break;
				case 'inbound-rtp':
					if (report.isRemote) {
						return;
					}
					// Get lastRtcStatsReport from transceiver switch stats
					const transceiverSwitchStats = transceiverSwitchStatsRef.current.find(
						(t) => t.mid === report.mid
					);
					if (transceiverSwitchStats && transceiverSwitchStats.lastRtcStatsReport) {
						const isVideo = report.kind === 'video';
						// calculate number of frames
						const keyFieldName = isVideo ? 'framesReceived' : 'packetsReceived';
						let framesPerUnit =
							report[keyFieldName] - transceiverSwitchStats?.lastRtcStatsReport[keyFieldName];
						// Add  frames per unit to frame stack
						if (transceiverSwitchStats?.frameStack)
							transceiverSwitchStats?.frameStack.push(report.timestamp, framesPerUnit);
						// Handle stable frames callbacks
						if (
							isVideo &&
							stableFramesCallbacksRef.current.length &&
							transceiverSwitchStats?.frameStack.isStable()
						) {
							let stableFramesCallback;
							do {
								stableFramesCallback = stableFramesCallbacksRef.current.shift();
								if (stableFramesCallback) stableFramesCallback();
							} while (stableFramesCallback);
						}
						// Do nothing if there is no iceRestart registered
						if (transceiverSwitchStats?.currentSwitchStats?.iceRestartAt) {
							// If there are no new frames, register the timestamp if not registered already and do nothing
							if (framesPerUnit === 0) {
								if (!transceiverSwitchStats?.currentSwitchStats?.lostFramesAt)
									transceiverSwitchStats.currentSwitchStats = {
										...transceiverSwitchStats?.currentSwitchStats!,
										lostFramesAt: transceiverSwitchStats?.lastRtcStatsReport.timestamp,
									};
								transceiverSwitchStats.currentSwitchStats = {
									...transceiverSwitchStats?.currentSwitchStats!,
									gainedFramesAt: null,
								};
							}
							// If theres new frames coming in
							else if (
								framesPerUnit > 0 &&
								transceiverSwitchStats?.currentSwitchStats?.lostFramesAt
							) {
								// Do nothing but registering gained frames timestamp and empty out framestack If we havent done it previously
								if (!transceiverSwitchStats?.currentSwitchStats?.gainedFramesAt) {
									// Empty out frame stack
									transceiverSwitchStats.frameStack.data = [
										transceiverSwitchStats?.frameStack.data[
											transceiverSwitchStats?.frameStack.data.length - 1
										],
									];
									transceiverSwitchStats.currentSwitchStats = {
										...transceiverSwitchStats?.currentSwitchStats,
										gainedFramesAt: transceiverSwitchStats?.lastRtcStatsReport.timestamp,
									};
								}
								// Do nothing If we registered stable frames timestamp already OR frame stack is not full OR sum of frames in framestack are less than required frames per second to qualify as stable
								else if (
									!transceiverSwitchStats?.currentSwitchStats?.stableFramesAt &&
									(!isVideo || transceiverSwitchStats?.frameStack.isStable())
								) {
									transceiverSwitchStats.currentSwitchStats = {
										...transceiverSwitchStats?.currentSwitchStats,
										stableFramesAt: report.timestamp,
									};
									const stat: SwitchStat = parseSwitchStat(transceiverSwitchStats, isVideo);
									// Call switch callback
									if (onSwitchStatCallback)
										onSwitchStatCallback({ mid: report.mid!, kind: report.kind, ...stat });
									addSessionStat(report.mid!, report.kind, stat);
									transceiverSwitchStats.statsId++;
									transceiverSwitchStats.currentSwitchStats = { ...switchStatInit };
								}
							}
						}
					}
					transceiverSwitchStats!.lastRtcStatsReport = report;
					break;
			}
		});
	};

	// Callback method to mark the start of the GoBe switch event
	const captureGoBeSwitchStats = () => {
		transceiverSwitchStatsRef.current.forEach((t) => {
			if (t.lastRtcStatsReport && t.currentSwitchStats?.iceRestartAt) {
				const { mid, kind } = t.lastRtcStatsReport,
					stat = parseSwitchStat(t, kind === 'video');

				// Call switch callback
				if (onSwitchStatCallback)
					onSwitchStatCallback({
						mid,
						kind,
						...stat,
					});
				addSessionStat(mid, kind, stat);
			}
			t.currentSwitchStats = {
				...switchStatInit,
				iceRestartAt: +new Date(),
			};
		});
	};

	// Handle stat mode initialization and changes
	useMemo(() => {
		// Do nothing if theres no peer connection
		if (!peerConnection) return;

		// Set the mode
		modeRef.current = mode;

		// Remove previous get stats loop
		clearInterval(intervalIdRef.current!);

		// Calculate sample rate based on mode
		const sampleRate = modeRef.current === StatMode.Intensive ? 100 : 1000;

		// Foreach transceiver stats
		transceiverSwitchStatsRef.current.forEach((t) => {
			// Reinitialize stats and counters
			t.currentSwitchStats = switchStatInit;
			t.statsId = 1;

			// Empty the framestack and reset the limit
			const frameStack = t.frameStack;
			frameStack.data = [];
			frameStack.limit = Math.floor(1000 / sampleRate);
		});

		// Start get stats loop
		intervalIdRef.current = setInterval(async () => {
			const report = await peerConnection?.getStats();
			if (report) onRTCStatsReport(report);
		}, sampleRate);

		// Expose switch stats if in Intensive mode
		if (!(window as any).getSwitchStats && modeRef.current === StatMode.Intensive)
			(window as any).getSwitchStats = (download = false) => {
				const value = {
					...sessionInfo,
					switchStats: sessionSwitchStatsRef.current,
				};
				if (download)
					downloadBlob(
						JSON.stringify(value),
						`switch_stats_${sessionInfo.sessionId}`,
						'application/json'
					);
				return value;
			};

		return modeRef.current;
	}, [mode, peerConnection]);

	// Increment peer connection id
	useEffect(() => {
		if (peerConnection) {
			transceiverSwitchStatsRef.current = [];
			peerConnection.ontrack = (e: RTCTrackEvent) => {
				const { transceiver: t } = e;
				transceiverSwitchStatsRef.current.push({
					mid: t.mid!,
					lastRtcStatsReport: null,
					frameStack: {
						data: [],
						limit: Math.floor(1000 / defaultSampleRate),
						push: function (timestamp: any, frames: any) {
							this.data.push({ timestamp, frames });
							if (this.data.length > this.limit) this.data.shift();
						},
						sum: function () {
							return this.data.reduce((prev: any, curr: any) => prev + curr.frames, 0);
						},
						isFull: function () {
							return this.data.length === this.limit;
						},
						isStable: function () {
							return this.isFull() && this.sum() >= stableFramesPerSecond;
						},
					} as any,
					currentSwitchStats: switchStatInit,
					statsId: 1,
				});
			};
			peerConnectionIdRef.current++;
		}
	}, [peerConnection]);

	// Clear get stat loop on unmount
	useEffect(() => {
		return () => {
			clearInterval(intervalIdRef.current!);
			intervalIdRef.current = undefined;
		};
	}, []);

	// Expose stats for automation purposes
	(window as any).transceiverSwitchStats = transceiverSwitchStatsRef.current;
	(window as any).sessionSwitchStats = sessionSwitchStatsRef.current;
	(window as any).enableIntensiveStats = () => {
		setMode(StatMode.Intensive);
		return true;
	};

	return {
		stats,
		captureGoBeSwitchStats,
		addStableFramesCallback: (callback: () => any) =>
			stableFramesCallbacksRef.current.push(callback),
	};
}
