import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Slider from '../slider';
import './index.scss';

import { setParameter } from 'actions/setParam';
import { SET_LOCAL_VOICE_VOLUME, SET_NAV_SPEED } from 'actions/types';
import { AppRootState } from 'reducers';
import Tooltip from '../tooltip';

import { AppContext } from 'context/appContext';
import { SessionState } from 'GoBeWebRTC/types';
import genericAlertIcon from 'images/alert.svg';
import redMicOffIcon from 'images/mic-off-red.svg';
import micIcon from 'images/mic.svg';
import redAlertIcon from 'images/red-alert.svg';
import whiteSpeedIcon from 'images/white-speed.svg';
import whiteStopIcon from 'images/white-stop.svg';

const reduxConnector = connect(
	(state: AppRootState) => ({
		controlDataChannel: state.sessionState.controlDataChannel,
		localVoiceVolume: state.sessionState.localVoiceVolume,
		navSpeed: state.sessionState.navSpeed,
		hideNavOptionsStatus: state.sessionState.hideNavOptionsStatus,
		microphoneLevel: state.hardwareState.settings.microphone.microphoneLevel,
		isAutoParking: state.sessionState.isAutoParking,
		isAbruptlyClosing: state.sessionState.isAbruptlyClosing,
	}),
	{ setParameter }
);

type PropsFromParent = {
	sessionState: SessionState;
	isStreamSynced: boolean;
	onClickHangUp: () => void;
	localStream: MediaStream | null;
	hasPrimaryVideoStartedPlaying: boolean;
	isDrivingImpaired: boolean;
	penalty: number;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const SessionOptions: React.FC<ComponentProps> = ({
	sessionState,
	localStream,
	isStreamSynced,
	onClickHangUp,
	hasPrimaryVideoStartedPlaying,
	controlDataChannel,
	localVoiceVolume,
	navSpeed,
	hideNavOptionsStatus,
	microphoneLevel,
	setParameter,
	isAutoParking,
	isDrivingImpaired,
	penalty,
	isAbruptlyClosing,
}: ComponentProps) => {
	const {
		navController: {},
	} = useContext(AppContext);
	const localVoiceRef = useRef<any>(null);
	const [localVoiceValue, changeLocalVoiceValue] = useState<any>('19');
	const icon = useMemo(() => (penalty === 1 ? redAlertIcon : genericAlertIcon), [penalty]);

	const onLocalVoiceChange = (value: number) => {
		setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, value);
		changeLocalVoiceValue(value);
		if (controlDataChannel && controlDataChannel.readyState === 'open') {
			controlDataChannel.send(`VOL ${value}`);
			controlDataChannel.send(JSON.stringify({ type: 'change_volume', volume: value }));
		}
	};

	useEffect(() => {
		if (microphoneLevel) {
			setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, microphoneLevel);
			changeLocalVoiceValue(microphoneLevel);
		}
	}, [microphoneLevel, setParameter]);

	const onLocalVoiceIconClick = () => {
		if (
			hasPrimaryVideoStartedPlaying &&
			controlDataChannel &&
			controlDataChannel.readyState === 'open'
		) {
			if (localVoiceVolume === '0') {
				if (localVoiceValue === '0') {
					if (controlDataChannel && controlDataChannel.readyState === 'open') {
						controlDataChannel.send(`VOL 19`);
						controlDataChannel.send(JSON.stringify({ type: 'change_volume', volume: 19 }));
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '19');
				} else {
					if (controlDataChannel && controlDataChannel.readyState === 'open') {
						controlDataChannel.send(`VOL ${localVoiceValue}`);
						controlDataChannel.send(
							JSON.stringify({ type: 'change_volume', volume: localVoiceValue })
						);
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, localVoiceValue);
				}
			} else {
				if (controlDataChannel && controlDataChannel.readyState === 'open') {
					controlDataChannel.send(`VOL 0`);
					controlDataChannel.send(JSON.stringify({ type: 'change_volume', volume: 0 }));
				}
				setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '0');
			}
		}
	};

	const onNavSpeedChange = (value: number) => {
		setParameter('navSpeed', SET_NAV_SPEED, value);
	};

	const isSessionOpen =
		hasPrimaryVideoStartedPlaying && controlDataChannel && controlDataChannel.readyState === 'open';

	useEffect(() => {
		if (sessionState === 'InProgress' && localStream && isStreamSynced) {
			if (parseInt(localVoiceVolume) === 0) {
				localStream.getAudioTracks()[0].enabled = false;
			} else {
				localStream.getAudioTracks()[0].enabled = true;
			}
		}
	}, [isStreamSynced, localStream, localVoiceVolume, sessionState]);

	return (
		<div className={hideNavOptionsStatus ? 'sessionOptionsContainer' : 'displayNone'}>
			<div
				className={`roundedButton ${
					!isSessionOpen || isAutoParking ? 'disabled' : 'rectangle navSpeedRectangle'
				}`}
			>
				<div>
					<img className="iconWrapper" src={whiteSpeedIcon} alt="" />
					{isDrivingImpaired ? <img className="badge" src={icon} alt="" /> : null}
				</div>
				{isSessionOpen && !isAutoParking && (
					<>
						<div className="localVoiceText">
							{(0.6 + (2.6 * parseInt(navSpeed)) / 100).toString().slice(0, 3)}
							<span>km/h</span>
						</div>
						<div className="rectangleLocalVoice">
							<Slider
								onChange={onNavSpeedChange}
								value={navSpeed}
								icon="speed-green.svg"
								id="navVideoSpeed"
							/>
						</div>
					</>
				)}
			</div>
			<div className={`roundedButton ${isSessionOpen ? 'rectangle' : 'disabled'}`}>
				<img
					className="iconWrapper"
					onClick={onLocalVoiceIconClick}
					src={parseInt(localVoiceVolume) === 0 ? redMicOffIcon : micIcon}
					alt=""
				/>
				{isSessionOpen && (
					<>
						<div className="localVoiceText">{localVoiceVolume}%</div>
						<div className="rectangleLocalVoice" ref={localVoiceRef}>
							<Slider
								onChange={onLocalVoiceChange}
								value={localVoiceVolume}
								icon={parseInt(localVoiceVolume) === 0 ? 'mic-off-red.svg' : 'mic-off-green.svg'}
								id="localVideoVolume"
							/>
						</div>
					</>
				)}
			</div>
			<Tooltip text="Pause/End" disabled={isAutoParking}>
				<div
					className={`roundedButton ${isAutoParking ? 'disabled' : 'red'}`}
					onClick={() => {
						if (!isAutoParking) onClickHangUp();
					}}
				>
					<img className="iconWrapper" src={whiteStopIcon} alt="" />
					{isAbruptlyClosing ? (
						<div className="circleRipple">
							<div className="circleRippleEffect" />
						</div>
					) : null}
				</div>
			</Tooltip>
		</div>
	);
};

export default reduxConnector(SessionOptions);
