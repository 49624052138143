import React, { useEffect, useRef, useState } from 'react';

type Props = {
	initialMinute?: number;
	initialSeconds?: number;
	classname?: string;
};

const Timer: React.FC<Props> = (props) => {
	const { initialSeconds = 0, classname } = props;
	const [secondsPassed, setSecondsPassed] = useState(initialSeconds);
	const startDateRef = useRef<number>(+new Date());
	const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

	useEffect(() => {
		if (intervalIdRef.current === undefined)
			intervalIdRef.current = setInterval(() => {
				setSecondsPassed(Math.floor((+new Date() - startDateRef.current) / 1000));
			}, 1000);
		return () => {
			clearInterval(intervalIdRef.current!);
		};
	}, []);

	return (
		<span className={classname}>
			{' '}
			{Math.floor(secondsPassed / 60)}:{secondsPassed % 60 < 10 ? '0' : ''}
			{secondsPassed % 60}
		</span>
	);
};

export default Timer;
