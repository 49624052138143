import { SessionInfo } from 'GoBeWebRTC/types';

export interface Size {
	height: number;
	width: number;
}
export type IActiveNavInput = 'keyboard' | 'joystick' | 'auto-docking';
export type RtpReceiverID = 'primaryCam' | 'navCam';
export enum RobotPrimaryCamera {
	WIDE_CAM = 'wide_cam',
	ZOOM_CAM = 'zoom_cam',
}
export interface PrimaryCameraState {
	currentPrimaryCamera: RobotPrimaryCamera;
	isChangingPrimaryCameraTo: RobotPrimaryCamera | null;
}

export type CameraConfig = {
	rotationDegrees: 0 | 90 | 180 | 270;
	crop: { top: number; right: number; bottom: number; left: number };
	deviation: { x: number; y: number };
	position?: {
		deviation_percentage: { x: number; y: number };
		dimension_percentage: { width: number; height: number };
	};
};

export type Reservation = {
	startDate: string;
	startDateOffsetInMs: number;
	endDate: string;
	endDateOffsetInMs: number;
	durationInMs: number;
	isGuest: boolean;
};

/** Session Info that is generate from Roster page */
export type LocalSessionInfo = SessionInfo & {
	reservation?: {
		isMyPermanentDevice: boolean;
		currentReservation?: Reservation;
		othersNextReservation?: Reservation;
	};
};

export type DragMode = 'draggable' | 'handle' | 'disabled';
