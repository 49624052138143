import React, { useMemo, useState } from 'react';
import './index.scss';

class PropsFromParent {
	onPatternSelect: (pattern: string | undefined) => any;
	selectedPatternName?: string;
}

const PatternPicker: React.FC<PropsFromParent> = ({
	onPatternSelect,
	selectedPatternName = 'None',
}: PropsFromParent) => {
	const [patterns, setPatterns] = useState<string[]>([
		'None',
		'Gradient',
		'Arrows',
		'Half-Rombes',
		'Upholstery',
		'Marrakesh',
		'Shippo',
		'Weave',
		'Brady Bunch',
		'Starry night',
		'Microbial Mat',
		'Zig-Zag',
		'Stars',
		'Stairs',
		'Rainbow bokeh',
		'Hearts',
		'Tablecloth',
		'Waves',
		'Lined paper',
		'Yin Yang',
		'Madras',
		'Polka dot',
		'Checkerboard',
		'Diagonal checkerboard',
		'Carbon',
		'Tartan',
		'Blueprint grid',
		'Diagonal stripes',
		'Seigaiha',
		'Cross-Dots',
		'Carbon fibre',
		'Bricks',
		'Cicada stripes',
		'Wave',
		'Japanese cube',
		'Vertical stripes',
		'Horizontal stripes',
		'Argyle',
		'Houndstooth',
		'HoneyComb',
		'Pyramid',
		'Chocolate Weave',
		'Cross',
		'Steps',
	]);

	const sortSelectedOnTop = (patterns: string[]) =>
		patterns.reduce((acc: string[], pattern: string) => {
			if (pattern === selectedPatternName) {
				return [pattern, ...acc];
			}
			return [...acc, pattern];
		}, []);

	useMemo(() => {
		setPatterns(sortSelectedOnTop(patterns));
		return patterns;
	}, []);

	return (
		<div className="patternPickerWrapper">
			<div className="patternContainer">
				{patterns.map((pattern) => (
					<div
						key={`pattern-${pattern}`}
						className={`pattern${selectedPatternName === pattern ? 'Selected' : ''}`}
						onClick={() => {
							if (pattern === selectedPatternName) return;
							onPatternSelect(pattern === 'None' ? undefined : pattern);
						}}
					>
						<div className={`patternPreview pattern${pattern.replace(' ', '-')}`} />
						<div className="patternName">{pattern}</div>
						{selectedPatternName === pattern ? (
							<div className="patternSelectedBanner">SELECTED</div>
						) : null}
					</div>
				))}
			</div>
		</div>
	);
};

export default PatternPicker;
