import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';

interface Props {
	text?: string;
	left?: boolean;
	showOnChange?: boolean;
	disabled?: boolean;
}

const Tooltip: React.FC<Props> = ({
	children,
	text: message,
	left,
	showOnChange,
	disabled,
	...rest
}) => {
	const [show, setShow] = useState(false);
	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
	const timeoutIdRef = useRef(timeoutId);
	const text = useMemo(() => {
		if (showOnChange) {
			setShow(true);
			clearTimeout(timeoutIdRef.current!);
			timeoutIdRef.current = setTimeout(() => {
				setShow(false);
			}, 5000);
		} else {
			clearTimeout(timeoutIdRef.current!);
			setShow(false);
		}
		return message;
	}, [message, showOnChange]);
	return (
		<div className="tooltip-container">
			{!disabled ? (
				<div
					className={text && show ? 'tooltip-box visible' : 'tooltip-box'}
					style={left ? { right: '60px', left: 'unset', whiteSpace: 'nowrap' } : undefined}
				>
					{text}
					<span
						className="tooltip-arrow"
						style={
							left
								? {
										right: '-10px',
										transform: 'rotate( -268deg)',
										left: 'unset',
								  }
								: undefined
						}
					/>
				</div>
			) : null}
			<div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} {...rest}>
				{children}
			</div>
		</div>
	);
};

export default Tooltip;
