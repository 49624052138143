import dragIcon from 'images/expand-outline.svg';
import { ReactElement, useMemo, useState } from 'react';
import { default as ReactDraggable } from 'react-draggable';
import { DragMode } from 'types';
import './index.scss';

type Props = {
	mode: DragMode;
	content: ReactElement;
	style?: React.CSSProperties;
};

function Draggable({ mode, content, style }: Props) {
	const showHandle = useMemo(() => mode === 'handle', [mode]);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	return (
		<ReactDraggable
			bounds="parent"
			{...(showHandle ? { handle: '.dragHandle' } : {})}
			disabled={mode === 'disabled'}
			onStart={() => setIsDragging(true)}
			onStop={() => setIsDragging(false)}
		>
			<div className="dragContainer" style={style}>
				{showHandle ? (
					<div className={`dragHandle ${isDragging ? 'dragHandleHover' : ''}`} id="dragHandle">
						<img className="dragHandleIcon" src={dragIcon} alt="" />
					</div>
				) : null}
				{content}
			</div>
		</ReactDraggable>
	);
}

export default Draggable;
