export function getAverageRGB(imgData: any) {
	function componentToHex(c: any) {
		const hex = c.toString(16);
		return hex.length == 1 ? '0' + hex : hex;
	}

	function rgbToHex(r: any, g: any, b: any) {
		return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
	}

	let blockSize = 5, // only visit every 5 pixels
		i = -4,
		length,
		rgb = { r: 0, g: 0, b: 0 },
		count = 0;

	length = imgData.data.length;

	while ((i += blockSize * 4) < length) {
		++count;
		rgb.r += imgData.data[i];
		rgb.g += imgData.data[i + 1];
		rgb.b += imgData.data[i + 2];
	}

	// ~~ used to floor values
	rgb.r = ~~(rgb.r / count);
	rgb.g = ~~(rgb.g / count);
	rgb.b = ~~(rgb.b / count);

	return rgbToHex(rgb.r, rgb.g, rgb.b);
}
