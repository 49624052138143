import React from 'react';

import Settings from 'components/settings';
import feedbackables from 'config/feedbackables.json';
import { AppContext } from 'context/appContext';
import useFeedbackController from 'hooks/useFeedbackController';
import useNavController from 'hooks/useNavController';
import useSettingsController from 'hooks/useSettingsController';
import FeedbackOverlay from 'pages/feedbackOverlay';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './common.scss';
import Lobby from './pages/lobby';
import Session from './pages/session';

const App: React.FC = () => {
	const settingsController = useSettingsController();
	const navController = useNavController();
	const feedbackController = useFeedbackController({
		feedbackables,
		settingsController,
	});
	return (
		<AppContext.Provider value={{ settingsController, navController, feedbackController }}>
			<FeedbackOverlay />
			<Settings />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Lobby />} />
					<Route path="/session" element={<Session />} />
				</Routes>
			</BrowserRouter>
		</AppContext.Provider>
	);
};

export default App;
