import { setParameter } from 'actions/setParam';
import { SET_FULL_SCREEN_STATUS } from 'actions/types';
import { AppContext } from 'context/appContext';
import React, { useContext, useEffect, useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRootState } from 'reducers';
import { isInFullscreen } from 'utils/fullScreen';

function requestMediaDevicesPermission() {
	return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
		// TODO: Stop the media tracks associated with the stream?
		stream.getTracks().forEach((track) => track.stop());
	});
}

const reduxConnector = connect((state: AppRootState) => ({}), { setParameter });

type Props = ConnectedProps<typeof reduxConnector>;

const Lobby: React.FC<Props> = ({ setParameter }) => {
	const { settingsController } = useContext(AppContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const localSessionInfo = searchParams.get('sessionInfo');

	useMemo<string | null>(() => {
		if (localSessionInfo)
			try {
				const pilotId = JSON.parse(decodeURIComponent(atob(localSessionInfo!))).pilotId;
				settingsController.setSettingsId(pilotId);
				settingsController.setSettingsValue(JSON.parse(localStorage.getItem(pilotId)!) as any);
			} catch (e) {
				console.warn(e);
			}
		return localSessionInfo;
	}, [localSessionInfo]);

	useEffect(() => {
		const onFullscreenChanged = () => {
			if (document.fullscreenElement) {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, true);
			} else {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, false);
			}
		};
		document.addEventListener('fullscreenchange', onFullscreenChanged);

		return () => {
			document.removeEventListener('fullscreenchange', onFullscreenChanged);
		};
	}, [setParameter]);

	useEffect(() => {
		if (localSessionInfo) {
			setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, isInFullscreen());
			requestMediaDevicesPermission().finally(() => {
				navigate('session/?sessionInfo=' + localSessionInfo);
				// NB: If permission request fails, the right error message will be displayed on Session page.
			});
		} else {
			console.error("No 'sessionInfo' was found in url");
			// TODO: Show an error. Currently, the user will be staring at a blank black screen
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <></>;
};

export default reduxConnector(Lobby);
