export function captureVideoFrame(video: any, format: any = 'jpeg', quality: any = 1) {
	if (!video || (format !== 'png' && format !== 'jpeg')) {
		return false;
	}

	const canvas = document.createElement('CANVAS') as any;

	canvas.width = video.videoWidth;
	canvas.height = video.videoHeight;

	canvas.getContext('2d').drawImage(video, 0, 0);

	return {
		imageData:
			video.videoWidth && video.videoHeight
				? canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height)
				: null,
	};
}
