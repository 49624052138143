import greenFlashIcon from 'images/green-flash2.svg';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { IActiveNavInput } from 'types';
import './index.scss';

import { AppContext } from 'context/appContext';
import drivingDisabledIcon from 'images/driving-disabled.svg';
import joystickInputIcon from 'images/joystick-controller.svg';
import keyboardInputIcon from 'images/keyboard-controller.svg';
import reconnectingIcon from 'images/sync-outline.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';

type Props = {
	isDrivingImpaired?: boolean;
	dockControllerStatus?: any;
	reconnecting: boolean;
};

const DrivingDisabledIndicatorConfig: { icon: string; label: ReactNode } = {
	label: <p className="indicator-label">Driving disabled</p>,
	icon: drivingDisabledIcon,
};

const NavInputIndicatorsConfig: Record<IActiveNavInput, { icon: string; label: React.ReactNode }> =
	{
		keyboard: {
			icon: keyboardInputIcon,
			label: <p className="indicator-label">Keyboard enabled</p>,
		},
		'auto-docking': {
			icon: greenFlashIcon,
			label: (
				<div className="docking-wrapper">
					<p className="indicator-label">GoBe Park enabled</p>
					<div className="chip">
						<p className="chip-label">BETA</p>
					</div>
				</div>
			),
		},
		joystick: {
			icon: joystickInputIcon,
			label: <p className="indicator-label">Joystick enabled</p>,
		},
	};

const ActiveNavigationInput: React.FC<Props> = ({
	isDrivingImpaired,
	dockControllerStatus,
	reconnecting,
}) => {
	const {
		navController: { activeNavInput, enabled: navControllerEnabled },
	} = useContext(AppContext);
	const [isMinimized, setIsMinimized] = useState(false);
	useEffect(() => {
		if (isDrivingImpaired && dockControllerStatus?.stage === 'DOCKING') {
			setIsMinimized(true);
		}
	}, [dockControllerStatus]);

	const indicatorConfig = reconnecting
		? {
				icon: reconnectingIcon,
				label: <p className="indicator-label">Reconnecting...</p>,
		  }
		: activeNavInput === null || !navControllerEnabled
		? DrivingDisabledIndicatorConfig
		: NavInputIndicatorsConfig[activeNavInput];
	return isMinimized ? (
		<div className="container isMinimized" onClick={() => setIsMinimized(false)}>
			<img className={`navIcon ${reconnecting ? 'spin' : ''}`} src={indicatorConfig.icon} alt="" />
		</div>
	) : (
		<div className="container">
			<img className={`navIcon ${reconnecting ? 'spin' : ''}`} src={indicatorConfig.icon} alt="" />
			{indicatorConfig.label}
			<img
				className="closeIconWrapper"
				onClick={() => setIsMinimized(true)}
				src={whiteCloseChevronIcon}
				alt=""
			/>
		</div>
	);
};

export default ActiveNavigationInput;
