import { CSSProperties, useEffect } from 'react';
import { useDominantColor } from './useDominantColor';

export function useVideoAura(
	videoRef: any,
	callback: (style: CSSProperties | null) => any,
	tick: number = 5000
) {
	// Use dominant color
	const { start, stop } = useDominantColor(
		videoRef,
		(color: string | null) => {
			const transition = `background-color ${tick}ms ease-in`;
			callback(
				color
					? {
							transition,
							MozTransition: transition,
							OTransition: transition,
							WebkitTransition: transition,
							backgroundColor: color,
					  }
					: null
			);
		},
		tick
	);

	useEffect(() => {
		return () => {
			stop();
		};
	}, []);

	return { start, stop };
}
