import { useState } from 'react';

export enum SettingPageSectionHeaders {
	APP_SETTINGS = 'App settings',
}

export enum SettingPageHeaders {
	APPEARANCE = 'Appearance',
	FEEDBACK = 'Feedback',
}

export enum SettingTabHeaders {
	GENERAL = 'General',
}

export enum SettingSectionHeaders {
	SKIN = 'Skin',
	AURA = 'Aura',
	RESET = 'Reset to default',
	FEEDBACKS = 'Feedbacks',
}

export enum SettingHeaders {
	REMOTE_CAM_SKIN = 'Remote camera container skin',
	NAV_CAM_SKIN = 'Navigation camera container skin',
	SHOW_AURA = 'Show AURA around remote and navigation cameras',
	RESET = 'Reset',
	FEEDBACK_GENERAL = 'Show Feedback',
	FEEDBACK_SESSION_END = 'Show session feedback',
	FEEDBACK_SWITCH = 'Show switch feedback',
}

export type Setting = {
	header: SettingHeaders;
	hideHeader?: boolean;
	description: string;
	value: any;
};

export type SettingSection = {
	header: SettingSectionHeaders;
	hideHeader?: boolean;
	description: string;
	children: { [setting: string]: Setting };
};

export type SettingTab = {
	header: SettingTabHeaders;
	description: string;
	children: { [section: string]: SettingSection };
};

export type SettingPage = {
	header: SettingPageHeaders;
	description: string;
	children: { [tab: string]: SettingTab };
};

export type SettingPageSection = {
	header: SettingPageSectionHeaders;
	description: string;
	children: { [page: string]: SettingPage };
};

export type Settings = {
	[header in SettingPageSectionHeaders]: SettingPageSection;
};

export type SettingsController = {
	setSettingsId: (id: string) => void;
	settings: { [header in SettingPageSectionHeaders]: SettingPageSection };
	isOpen: boolean;
	toggle: () => void;
	setSettingsValue: (settings: {
		[header in SettingHeaders]?: any;
	}) => void;
	setSettingValue: (header: SettingHeaders, value: any) => void;
	reset: (settingGroup: SettingGroup) => void;
};

export type SettingGroup = SettingPageSection | SettingPage | SettingTab | SettingSection | Setting;

const useSettingsController = () => {
	const [settingsId, setSettingsId] = useState<string | null>();
	const getDefaultSetting: (header: SettingHeaders) => any = (header) => {
		switch (header) {
			case SettingHeaders.REMOTE_CAM_SKIN:
				return undefined;
			case SettingHeaders.NAV_CAM_SKIN:
				return undefined;
			case SettingHeaders.SHOW_AURA:
				return false;
			case SettingHeaders.FEEDBACK_GENERAL:
				return true;
			case SettingHeaders.FEEDBACK_SESSION_END:
				return true;
			case SettingHeaders.FEEDBACK_SWITCH:
				return false;
		}
	};
	const defaultSettings: {
		[header in SettingPageSectionHeaders]: SettingPageSection;
	} = {
		[SettingPageSectionHeaders.APP_SETTINGS]: {
			header: SettingPageSectionHeaders.APP_SETTINGS,
			description: '',
			children: {
				[SettingPageHeaders.APPEARANCE]: {
					header: SettingPageHeaders.APPEARANCE,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.SKIN]: {
									header: SettingSectionHeaders.SKIN,
									description: '',
									children: {
										[SettingHeaders.REMOTE_CAM_SKIN]: {
											header: SettingHeaders.REMOTE_CAM_SKIN,
											description: '',
											value: getDefaultSetting(SettingHeaders.REMOTE_CAM_SKIN),
										},
										[SettingHeaders.NAV_CAM_SKIN]: {
											header: SettingHeaders.NAV_CAM_SKIN,
											description: '',
											value: getDefaultSetting(SettingHeaders.NAV_CAM_SKIN),
										},
									},
								},
								[SettingSectionHeaders.AURA]: {
									header: SettingSectionHeaders.AURA,
									description: '',
									children: {
										[SettingHeaders.SHOW_AURA]: {
											header: SettingHeaders.SHOW_AURA,
											description: '',
											value: getDefaultSetting(SettingHeaders.SHOW_AURA),
										},
									},
								},
								[SettingSectionHeaders.RESET]: {
									header: SettingSectionHeaders.RESET,
									description: '',
									children: {
										[SettingHeaders.RESET]: {
											header: SettingHeaders.RESET,
											hideHeader: true,
											description: '',
											value: null,
										},
									},
								},
							},
						},
					},
				},
				[SettingPageHeaders.FEEDBACK]: {
					header: SettingPageHeaders.FEEDBACK,
					description: '',
					children: {
						[SettingTabHeaders.GENERAL]: {
							header: SettingTabHeaders.GENERAL,
							description: '',
							children: {
								[SettingSectionHeaders.FEEDBACKS]: {
									header: SettingSectionHeaders.FEEDBACKS,
									description: '',
									children: {
										[SettingHeaders.FEEDBACK_GENERAL]: {
											header: SettingHeaders.FEEDBACK_GENERAL,
											description: '',
											value: getDefaultSetting(SettingHeaders.FEEDBACK_GENERAL),
										},
										[SettingHeaders.FEEDBACK_SESSION_END]: {
											header: SettingHeaders.FEEDBACK_SESSION_END,
											description: '',
											value: getDefaultSetting(SettingHeaders.FEEDBACK_SESSION_END),
										},
										[SettingHeaders.FEEDBACK_SWITCH]: {
											header: SettingHeaders.FEEDBACK_SWITCH,
											description: '',
											value: getDefaultSetting(SettingHeaders.FEEDBACK_SWITCH),
										},
									},
								},
								[SettingSectionHeaders.RESET]: {
									header: SettingSectionHeaders.RESET,
									description: '',
									children: {
										[SettingHeaders.RESET]: {
											header: SettingHeaders.RESET,
											hideHeader: true,
											description: '',
											value: null,
										},
									},
								},
							},
						},
					},
				},
			},
		},
	};
	const [settings, setSettings] = useState<Settings>(defaultSettings);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	// Sets the setting value
	const setSettingValue = (header: SettingHeaders, value: any) => {
		setSettings((prevSettings: any) => {
			// find setting
			let found: boolean = false;
			for (let [pageSectionHeader] of Object.entries(prevSettings)) {
				for (let [pageHeader] of Object.entries(prevSettings[pageSectionHeader].children)) {
					for (let [tabHeader] of Object.entries(
						prevSettings[pageSectionHeader].children[pageHeader].children
					)) {
						for (let [sectionHeader] of Object.entries(
							prevSettings[pageSectionHeader].children[pageHeader].children[tabHeader].children
						)) {
							for (let [settingHeader] of Object.entries(
								prevSettings[pageSectionHeader].children[pageHeader].children[tabHeader].children[
									sectionHeader
								].children
							)) {
								if (settingHeader === header) {
									prevSettings[pageSectionHeader].children[pageHeader].children[tabHeader].children[
										sectionHeader
									].children[settingHeader].value = value;
									if (settingsId) {
										localStorage.setItem(
											settingsId,
											JSON.stringify({
												...JSON.parse(localStorage.getItem(settingsId)!),
												[settingHeader]: value,
											})
										);
									}
									found = true;
								}
								if (found) break;
							}
							if (found) break;
						}
						if (found) break;
					}
					if (found) break;
				}
				if (found) break;
			}
			return { ...prevSettings };
		});
	};

	// Sets settings value
	const setSettingsValue: (settings: {
		[header in SettingHeaders]?: any;
	}) => void = (settings) =>
		settings &&
		Object.entries(settings).forEach(([header, value]) =>
			setSettingValue(header as SettingHeaders, value)
		);

	// Toggle to show the setting page
	const toggle = () => {
		setIsOpen((prev) => !prev);
	};

	/**
	 * Resets the settings
	 */
	const reset = (settingGroup: SettingGroup) => {
		if ('value' in settingGroup)
			setSettingValue(settingGroup.header, getDefaultSetting(settingGroup.header));
		else Object.values(settingGroup.children).forEach((child) => reset(child));
	};

	return {
		setSettingsId,
		setSettingsValue,
		settings,
		isOpen,
		toggle,
		setSettingValue,
		reset,
	} as SettingsController;
};

export default useSettingsController;
