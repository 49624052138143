import { AppContext } from 'context/appContext';
import _, { debounce, throttle } from 'lodash';
import React, {
	DetailedHTMLProps,
	HTMLAttributes,
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { NavController } from '../../../../hooks/useNavController';
import useNavInputWindowFocus from './useNavInputWindowFocus';

const _NAV_KEYS = [
	'ArrowUp',
	'w',
	'W',
	'ArrowDown',
	's',
	'S',
	'ArrowRight',
	'd',
	'D',
	'ArrowLeft',
	'a',
	'A',
] as const;

const NAV_KEYS = new Set(_NAV_KEYS);
type NavKey = typeof _NAV_KEYS[number];
type DirectionalKeysPressState = {
	up: 0 | 1;
	down: 0 | 1;
	left: 0 | 1;
	right: 0 | 1;
};
const KeyToDirectionMap: Record<typeof _NAV_KEYS[number], keyof DirectionalKeysPressState> = {
	ArrowUp: 'up',
	w: 'up',
	W: 'up',
	ArrowDown: 'down',
	s: 'down',
	S: 'down',
	ArrowRight: 'right',
	d: 'right',
	D: 'right',
	ArrowLeft: 'left',
	a: 'left',
	A: 'left',
};

const _DOCKING_KEYS = ['P', 'p'] as const;
const AUTO_DOCKING_KEYS = new Set(_DOCKING_KEYS);
type AutoDockingKey = typeof _DOCKING_KEYS[number];

const [KEY_UP_STATE, KEY_DOWN_STATE] = [0, 1];
/** How frequent (in milliseconds) that the KeyboardInput will emit nav commands */
const KEYBOARD_INPUT_EVENT_PERIOD_MS = 100;
/**
 * If no key-presses are detected for this number of times (one per each loop iteration),
 * we stop emitting nav-commands event
 */
const MAX_CONSECUTIVE_ALL_ZEROS_COUNT = 10;

const NO_KEYS_PRESSED_STATE: DirectionalKeysPressState = {
	up: 0,
	down: 0,
	right: 0,
	left: 0,
};
class _ImperativeKeyboardInput {
	private loopId: ReturnType<typeof setInterval> | undefined;
	/** Tracking of key presses on the bound `html element` */
	private keysStates: DirectionalKeysPressState = NO_KEYS_PRESSED_STATE;
	private eventTarget = new EventTarget();
	private consecutiveAllZerosKeyStateCount = 0;

	constructor(
		private readonly navController: Pick<NavController, 'onNavCommand'>,
		private _isFocused = false
	) {
		this.loop = throttle(this.loop.bind(this), KEYBOARD_INPUT_EVENT_PERIOD_MS / 5, {
			trailing: true,
		});
	}

	private startLoop = () => {
		if (!this._isFocused) return;
		// Do not setInterval if there is one running already
		if (this.loopId === undefined) {
			this.loopId = setInterval(this.loop, KEYBOARD_INPUT_EVENT_PERIOD_MS);
		}
	};
	private stopLoop = () => {
		if (this.loopId !== undefined) {
			clearInterval(this.loopId);
			this.loopId = undefined;
		}
	};

	private loop = () => {
		if (!this._isFocused) {
			// console.debug('abort ImperativeKeyboardInput.loop() -> disabled');
			return;
		}
		// console.debug("ImperativeKeyboardInput.loop()", this.keysStates);
		const isAllZeros = Object.values(this.keysStates).every((val) => val === 0);
		if (isAllZeros) {
			this.consecutiveAllZerosKeyStateCount += 1;
			if (this.consecutiveAllZerosKeyStateCount > MAX_CONSECUTIVE_ALL_ZEROS_COUNT - 1) {
				this.stopLoop();
				this.consecutiveAllZerosKeyStateCount = 0;
			}
		}

		this.navController.onNavCommand({
			linear: this.keysStates.up - this.keysStates.down,
			angular: this.keysStates.left - this.keysStates.right,
		});
	};

	public onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (!this._isFocused) {
			return;
		}
		if (!(NAV_KEYS.has(e.key as NavKey) || AUTO_DOCKING_KEYS.has(e.key as AutoDockingKey))) {
			console.warn(`abort ImperativeKeyboardInput.onKeyDown() -> invalid key '${e.key}'`);
			this.blur();
			return;
		}
		if (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey) {
			console.warn(`abort ImperativeKeyboardInput.onKeyDown() -> modifier key pressed`);
			this.blur();
			return;
		}

		const oldKeyState = this.keysStates[KeyToDirectionMap[e.key as NavKey]];
		this.keysStates = {
			...this.keysStates,
			[KeyToDirectionMap[e.key as NavKey]]: KEY_DOWN_STATE,
		};

		const didKeyStateChange = oldKeyState !== KEY_DOWN_STATE;
		if (didKeyStateChange) {
			this.loop(); // process new different state immediately
		}
		this.startLoop();
		// IMPORTANT: LOGIC: the just-set directionsState will be processed in the next iteration of the loop
	};

	public onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (!this._isFocused) {
			return;
		}
		if (!NAV_KEYS.has(e.key as NavKey)) {
			console.warn(`abort ImperativeKeyboardInput.onKeyUp() -> invalid key '${e.key}'`);
			this.blur();
			return;
		}
		if (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey) {
			console.warn(`abort ImperativeKeyboardInput.onKeyUp() -> modifier key pressed`);
			this.blur();
			return;
		}

		const oldKeyState = this.keysStates[KeyToDirectionMap[e.key as NavKey]];
		this.keysStates = {
			...this.keysStates,
			[KeyToDirectionMap[e.key as NavKey]]: KEY_UP_STATE,
		};
		const didKeyStateChange = oldKeyState !== KEY_UP_STATE;
		if (didKeyStateChange) {
			this.loop(); // process new different state immediately
		}
		this.startLoop(); // will start loop if it's not running already
		// LOGIC: the just-set directionsState will be processed in the next iteration of the loop
	};

	/**
	 * For every key-up event received on the bound `html element`, `document` would have received it too.
	 * But the vice-versa is not true.
	 * In very rare cases, the bound `html div element` might miss a key-up event.
	 * We thus also track key-up events on the document, and clear the corresponding key's state.
	 * This ensures a safe navigation with close to 0 probability of the much dreaded `ghost navigation`
	 */
	public onDocumentKeyUp = (e: any) => {
		if (!NAV_KEYS.has(e.key as NavKey)) return;

		this.keysStates = {
			...this.keysStates,
			[KeyToDirectionMap[e.key as NavKey]]: KEY_UP_STATE,
		};
	};

	/**
	 * Activate the input.
	 * It may now be able to handle key-up and key-down events.
	 *
	 * IMPORTANT: This function can safely be called multiple times in a row.
	 * The only side effect, is that a `focus` event will be emitted each time
	 * */
	public focus = () => {
		// console.debug('ImperativeKeyboardInput.focus()');
		this._isFocused = true; // IMPORTANT: This is the only way/place we set this to true
		// TODO: Assess if it's okay to not fire the event if already focused
		this.eventTarget.dispatchEvent(new Event('focus'));
	};

	/**
	 * Deactivate the input.
	 * It will not be able to handle key-up and key-down events until activated again
	 *
	 * IMPORTANT: This function can safely be called multiple times in a row.
	 * The only side-effect, is that a `blur` event will be fired each time
	 * */
	public blur = () => {
		// console.debug('ImperativeKeyboardInput.blur()');

		if (this._isFocused) {
			this.stopLoop();
			this.keysStates = NO_KEYS_PRESSED_STATE; // imitate no-keys pressed
			this.loop(); // handle the just-set NO_KEYS_PRESSED_STATE immediately
			this._isFocused = false; // IMPORTANT: This is the only place where we set this to false
		}

		// TODO: Assess if it's okay to not fire the event if already blurred
		this.eventTarget.dispatchEvent(new Event('blur'));
	};

	public get isFocused() {
		// the only way to enable the imperative input, is to focus it
		return this._isFocused;
	}

	public addEventListener = (event: 'focus' | 'blur', listener: (...args: any[]) => void): this => {
		this.eventTarget.addEventListener(event, listener);
		return this;
	};

	public removeEventListener = (
		event: 'focus' | 'blur',
		listener: (...args: any[]) => void
	): this => {
		this.eventTarget.removeEventListener(event, listener);
		return this;
	};
}

/** Public API that can be used imperatively */
export type ImperativeKeyboardNavInput = Pick<
	_ImperativeKeyboardInput,
	'addEventListener' | 'removeEventListener' | 'isFocused'
>;
type KeyboardNavInputProps = {
	children?: React.ReactNode;
	className?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>['className'];
	/** If false, then the input can handle user-generated events */
	disabled: boolean;
};
const KeyboardNavInput = React.forwardRef<ImperativeKeyboardNavInput, KeyboardNavInputProps>(
	({ children, className, disabled: navInputDisabled }, keyboardNavInputRef) => {
		const { navController } = useContext(AppContext);
		const onFocusChanged = useCallback(
			(isFocused: boolean) => {
				navController.onNavInputFocusChanged('keyboard', isFocused);
			},
			[navController]
		);

		const disabled = useMemo(
			() =>
				navInputDisabled ||
				!(navController.activeNavInput === 'keyboard' || navController.activeNavInput === null) ||
				!navController.enabled,
			[navInputDisabled, navController.activeNavInput, navController.enabled]
		);
		const divID = useRef(`keyboard-nav-input-${Math.random().toString(16).substr(2)}`);
		const divElement = useRef<HTMLDivElement | null>(null);
		const keyboardInput = useRef(new _ImperativeKeyboardInput(navController));

		const [isWindowFocused, setIsWindowFocused] = useState(document.hasFocus());
		const onWindowFocus = () => setIsWindowFocused(true);
		const onWindowBlur = () => setIsWindowFocused(false);

		// bind to the forwarded ref if any
		useLayoutEffect(() => {
			if (keyboardNavInputRef == null) return;

			const refValue: ImperativeKeyboardNavInput = _.pick(
				keyboardInput.current,
				'addEventListener',
				'removeEventListener',
				'isFocused'
			);
			if (keyboardNavInputRef instanceof Function) {
				keyboardNavInputRef(refValue);
			} else {
				// keyboardNavInputRef.current = refValue;
			}

			return () => {
				if (keyboardNavInputRef instanceof Function) {
					keyboardNavInputRef(null);
				} else {
					// keyboardNavInputRef.current = null;
				}
			};
		}, [keyboardNavInputRef]);

		useLayoutEffect(() => {
			/**
			 * Hi there 👋🏾, do not throttle this function ⛔️,
			 * 		unless you can guarantee that the resulting function
			 * 		will always run before onKeyUp (of the bound `html div element`).
			 *
			 * See the comment on div.onKeyDown below for an explanation as to why this should not be throttled.
			 * I feel lazy typing out the same message/explanation again here 😅
			 */
			const onAncestorKeyUp = keyboardInput.current.onDocumentKeyUp;
			// registered in the capture phase,
			//	onDocumentKeyUp is guaranteed to be executed before onKeyUp (of the bound `html div element`)
			// Also `capture` phase cannot be cancelled!
			const options: AddEventListenerOptions = { capture: true };

			document.addEventListener('keyup', onAncestorKeyUp, options);
			/* See https://stackoverflow.com/a/2671217/4906477
				In a perfect world, we would have registered the keyup event on document only, and it should be good.
				But unfortunately, we dont live in a perfect world. 😔
				So we bind to `window` too, just to be sure we didn't miss anything.
			 */
			window.addEventListener('keyup', onAncestorKeyUp, options);
			return () => {
				document.removeEventListener('keyup', onAncestorKeyUp, options);
				window.removeEventListener('keyup', onAncestorKeyUp, options);
			};
		}, []);

		// keep track and notify parent component of focus changes
		useLayoutEffect(() => {
			if (!onFocusChanged) return;

			const input = keyboardInput.current;

			const onFocus = () => onFocusChanged!(true);

			const onBlur = () => onFocusChanged!(false);

			input.addEventListener('focus', onFocus);
			input.addEventListener('blur', onBlur);

			return () => {
				input.removeEventListener('focus', onFocus);
				input.removeEventListener('blur', onBlur);
			};
		}, [onFocusChanged]);

		const focus = useCallback(() => {
			if (!isWindowFocused) {
				// console.debug('abort KeyboardNavInput.focus() -> window is not focused');
				return;
			}
			if (disabled) {
				// console.debug('abort KeyboardNavInput.focus() -> disabled');
				return;
			}

			keyboardInput.current.focus();

			const isDivElementFocused = !!document.activeElement?.closest(`#${divID.current}`);
			if (!isDivElementFocused) {
				// focus the div so that we can get corresponding `blur` event
				divElement.current?.focus({ preventScroll: true });
				// console.debug(`KeyboardNavInput.div.focus() -> ${divElement.current != null}`);
			}
		}, [disabled, isWindowFocused]);

		const onMouseMove = useMemo(() => debounce(focus, 1000, { leading: true }), [focus]);

		const blur = useCallback(() => {
			onMouseMove.cancel();
			keyboardInput.current.blur();

			const isDivElementFocused = !!document.activeElement?.closest(`#${divID.current}`);
			if (isDivElementFocused) {
				// blur the div, so that we can get corresponding `focus` event
				divElement.current?.blur();
				// console.debug(`KeyboardNavInput.div.blur() -> ${divElement.current != null}`);
			}
		}, [onMouseMove]);

		// lose focus when nav-input component is about to be unmounted
		// eslint-disable-next-line react-hooks/exhaustive-deps
		useLayoutEffect(() => () => blur(), []); // IMPORTANT: `blur` is not added to deps array - effect should run only once

		// lose focus when nav-input is disabled
		useLayoutEffect(() => {
			if (disabled) blur();
			else focus();
		}, [disabled, focus, blur]);

		useNavInputWindowFocus({
			disabled,
			onFocus: focus,
			onBlur: blur,
			onWindowBlur,
			onWindowFocus,
		});

		return (
			<div
				id={divID.current}
				ref={divElement}
				// style={isFocused ? FOCUSED_KEYBOARD_INPUT_STYLE : undefined}
				className={className}
				// a tabIndex is required so that we can receive focus/blur events
				// https://stackoverflow.com/questions/3656467/is-it-possible-to-focus-on-a-div-using-javascript-focus-function
				tabIndex={-1} // <-- focusable, but not via a Tab key
				// register the event handlers in 'capture' phase,
				// 		so that it cannot be cancelled via stopPropagation in a child element
				onClickCapture={() => {
					if (!navController.activeNavInput) focus();
				}}
				onMouseEnter={focus}
				onMouseOver={focus}
				onMouseMoveCapture={() => {
					if (!navController.activeNavInput) focus();
				}}
				onBlurCapture={blur}
				onContextMenuCapture={blur}
				onMouseLeave={blur}
				/**
				 * Hi there 👋🏾, please dont throttle this function ⛔️. You will most likely be wasting your time.
				 *
				 * But... (you will say), the frequency of keydown events, can be pretty huge - I know 🙂.
				 * Especially if the user presses two (or more keys) at the same time repeatedly - also yes, I know.
				 *
				 * This can be a problem for less resourceful computers, so we would normally throttle the event handler.
				 * However, in this particular case, we cannot do that directly on the event handler. 👎🏾
				 *
				 * We would want to be able to record (not necessarily process) every single keyup/keydown event without missing any of them,
				 * 	otherwise we will result in the much dreaded 'ghost navigation'.
				 * Instead, we throttle the underlying function that sends the navigation command to the robot.
				 *
				 * So you see, we actually throttle 😀
				 * - Prince
				 */
				onKeyDown={disabled ? undefined : keyboardInput.current.onKeyDown}
				onKeyUp={disabled ? undefined : keyboardInput.current.onKeyUp}
			>
				{children}
			</div>
		);
	}
);
KeyboardNavInput.displayName = 'KeyboardNavInput';
export default KeyboardNavInput;
