import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import './index.scss';

import { setParameter } from 'actions/setParam';
import BetaLabel from 'components/betaLabel';
import FeedbackCard from 'components/feedbackCard';
import PatternPicker from 'components/patternPicker';
import ToggleButton from 'components/toggleButton';
import { AppContext } from 'context/appContext';
import { Feedbackable } from 'hooks/useFeedbackController';
import {
	Setting,
	SettingHeaders,
	SettingPage,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTab,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { AppRootState } from 'reducers';
import { feedbackNameToSettingHeader } from 'utils/feedbackNameToSettingHeader';

const reduxConnector = connect((state: AppRootState) => ({}), { setParameter });

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux;

const Settings: React.FC<ComponentProps> = ({}: ComponentProps) => {
	const { settingsController, feedbackController } = useContext(AppContext);
	const [selectedSettingPage, setSelectedSettingPage] = useState<SettingPage | null>(null);
	const [selectedTab, setSelectedTab] = useState<SettingTab | null>(null);
	const onPageClick = (page: SettingPage) => {
		if (selectedSettingPage !== page) setSelectedSettingPage(page);
	};
	const onTabClick = (tab: SettingTab) => setSelectedTab(tab);
	const parsedFeedbackables = feedbackController.feedbackables.map((feedbackable) => ({
		...feedbackable,
		initialDelay: 0,
		timeout: 0,
		muteSound: true,
		settingHeader: feedbackNameToSettingHeader(feedbackable.name),
	}));
	let parsedFeedbackable;
	const renderSetting = (
		setting: Setting,
		callbacks?: {
			[name: string]: (...args: any[]) => any;
		}
	) => {
		switch (setting.header) {
			case SettingHeaders.REMOTE_CAM_SKIN:
				return (
					<PatternPicker
						selectedPatternName={
							settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
								SettingPageHeaders.APPEARANCE
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.SKIN].children[
								SettingHeaders.REMOTE_CAM_SKIN
							].value
						}
						onPatternSelect={(pattern) =>
							settingsController.setSettingValue(SettingHeaders.REMOTE_CAM_SKIN, pattern)
						}
					/>
				);
			case SettingHeaders.NAV_CAM_SKIN:
				return (
					<PatternPicker
						selectedPatternName={
							settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
								SettingPageHeaders.APPEARANCE
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.SKIN].children[
								SettingHeaders.NAV_CAM_SKIN
							].value
						}
						onPatternSelect={(pattern) =>
							settingsController.setSettingValue(SettingHeaders.NAV_CAM_SKIN, pattern)
						}
					/>
				);
			case SettingHeaders.SHOW_AURA:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
								SettingPageHeaders.APPEARANCE
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.AURA].children[
								SettingHeaders.SHOW_AURA
							].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.SHOW_AURA, value)
						}
					/>
				);
			case SettingHeaders.FEEDBACK_GENERAL:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
								SettingPageHeaders.FEEDBACK
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
								.children[SettingHeaders.FEEDBACK_GENERAL].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.FEEDBACK_GENERAL, value)
						}
					/>
				);
			case SettingHeaders.FEEDBACK_SESSION_END:
				parsedFeedbackable = parsedFeedbackables.find(
					(feedbackable) => feedbackable.settingHeader === SettingHeaders.FEEDBACK_SESSION_END
				);
				return (
					<div className="innerSetting">
						<ToggleButton
							value={
								settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_SESSION_END].value
							}
							onChange={(value) =>
								settingsController.setSettingValue(SettingHeaders.FEEDBACK_SESSION_END, value)
							}
							disabled={
								!settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_GENERAL].value
							}
						/>
						<div className="innerSettingDisabled">
							<FeedbackCard
								feedbackable={parsedFeedbackable as Feedbackable}
								onDismiss={() => {}}
								onSubmit={(rating, review) => {}}
							/>
						</div>
					</div>
				);
			case SettingHeaders.FEEDBACK_SWITCH:
				parsedFeedbackable = parsedFeedbackables.find(
					(feedbackable) => feedbackable.settingHeader === SettingHeaders.FEEDBACK_SWITCH
				);
				return (
					<div className="innerSetting">
						<ToggleButton
							value={
								settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_SWITCH].value
							}
							onChange={(value) =>
								settingsController.setSettingValue(SettingHeaders.FEEDBACK_SWITCH, value)
							}
							disabled={
								!settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_GENERAL].value
							}
						/>
						<div className="innerSettingDisabled">
							<FeedbackCard
								feedbackable={parsedFeedbackable as Feedbackable}
								onDismiss={() => {}}
								onSubmit={(rating, review) => {}}
							/>
						</div>
					</div>
				);
			case SettingHeaders.RESET:
				return (
					<div className="settingsButton" onClick={callbacks?.reset}>
						{setting.header.toUpperCase()}
					</div>
				);
		}
	};

	// Select the general tab of the setting page
	useMemo(() => {
		if (selectedSettingPage)
			setSelectedTab(selectedSettingPage.children[SettingTabHeaders.GENERAL]);
		return selectedSettingPage;
	}, [selectedSettingPage]);

	// Select the default setting page
	useEffect(
		() =>
			setSelectedSettingPage(
				settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
					SettingPageHeaders.APPEARANCE
				]
			),
		[]
	);

	return settingsController.isOpen ? (
		<div className="settingsContainer">
			<div className="settings">
				<div className="settingsMenuContainer">
					<div className="settingsMenuContext">
						<div className="settingsMenuHeader">
							<BetaLabel />
						</div>
						<div className="settingsMenu">
							{Object.entries(settingsController.settings).map(
								([pageSectionHeader, pageSection]) => (
									<div key={`page-section-${pageSectionHeader}`} className="pageSection">
										<div className="settingSectionHeader">{pageSectionHeader.toUpperCase()}</div>
										<div className="pageSectionPages">
											{Object.entries(pageSection.children).map(([pageHeader, page]) => (
												<div
													key={`page-${pageHeader}`}
													className={`settingsHandleButton${
														pageHeader === selectedSettingPage?.header ? 'Selected' : ''
													}`}
													onClick={() => onPageClick(page)}
												>
													{pageHeader}
												</div>
											))}
										</div>
									</div>
								)
							)}
						</div>
					</div>
					<div className="closeButton" onClick={() => settingsController.toggle()}>
						Close
					</div>
				</div>
				<div className="settingsContext">
					<div className="tabSection">
						{selectedSettingPage &&
							Object.entries(selectedSettingPage?.children).map(([tabHeader, tab]) => (
								<div
									key={`tab-${tabHeader}`}
									className={`settingsHandleButton${
										tabHeader === selectedTab?.header ? 'Selected' : ''
									}`}
									onClick={() => onTabClick(tab)}
								>
									{tabHeader}
								</div>
							))}
					</div>
					<div className="tabContext">
						{selectedTab?.children &&
							Object.entries(selectedTab?.children).map(([sectionHeader, section]) => (
								<div key={`tab-section-${sectionHeader}`} className="settingSection">
									{!section.hideHeader ? (
										<div className="settingSectionHeader">{sectionHeader.toUpperCase()}</div>
									) : null}
									<div className="settingsWrapper">
										{Object.entries(section.children).map(([settingHeader, setting]) => (
											<div key={`setting-${settingHeader}`} className="setting">
												{!setting.hideHeader ? (
													<div className="settingHeader">{settingHeader}</div>
												) : null}
												<div className="settingContainer">
													{renderSetting(setting, {
														reset: () => settingsController.reset(selectedTab),
													})}
												</div>
											</div>
										))}
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default reduxConnector(Settings);
